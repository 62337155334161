.App {
  /*margin-top: 15px;*/
  /*margin-bottom: 30px;*/
}

.App .navbar-brand {
  font-weight: bold;
}

html {
  overflow-x: hidden;
}

@media only screen and (max-width: 600px) {
  .sslFormLogo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
  }
}


@media all and (min-width: 600px) {
  .sslFormLogo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20%;
  }
}

